import React from 'react';

import { Box, Flex, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react';

import Paginate from '../../../../../components/Paginate/Paginate';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import TripListCollapse from '../../../components/Tables/Travel/TripListCollapse';

const TripList = ({ list, isLoading, metadata, setMetadata, action, setAction }) => {
  const headers = [
    '',
    '',
    'Número',
    'Empresas vinculadas',
    'Motorista',
    'Início',
    'Conclusão',
    '',
    'Arquivos',
    'Status',
    'Tipo',
    '',
    '',
  ];

  return (
    <>
      <ScreenLoader isLoading={isLoading} overflowY="auto">
        <Table w="full" m='10px'>
          <Thead>
            <Tr className="border">
              {headers.map((header, index) => (
                <Th key={index}>
                  <Box p="0px 24px !important">{header && <Text>{header}</Text>}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {list.map((entity, key) => (
              <TripListCollapse entity={entity} key={key} />
            ))}
          </Tbody>
        </Table>

        {isLoading === false && list?.length === 0 && (
          <Flex justify="center" my="25px">
            Nenhum dado encontrado para os parâmetros filtrados.
          </Flex>
        )}
      </ScreenLoader>

      {metadata?.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default TripList;
