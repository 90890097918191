import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Flex, List, ListItem, TabPanel, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';

import permissions from '../../../../../services/permissions';
import requests from '../../../../../services/requests';
import { modalityTitle, typeTransportTitle } from '../../../../../utils/translate';

const InformationTab = ({ entity, setCenter, setZoom }) => {
  const [estimatedEnds, setEstimatedEnds] = useState(null);
  const [estimatedEndsTooltip, setEstimatedEndsTooltip] = useState('Data esperada');
  let navigate = useNavigate();
  const hasPermission = permissions.comexProcess;
  const tooltipStart = (
    <>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
      {entity?.advanceTripStart == null && entity?.delayTripStart == null && <Text>No horário</Text>}
    </>
  );

  const tooltipEnd = (
    <>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
      {entity?.advanceTripEnd == null && entity?.delayTripEnd == null && <Text>No horário</Text>}
    </>
  );

  const fetchEstimatedEnds = async (identifier, status, ends) => {
    if (status != 'TRAVELLING') {
      setEstimatedEnds(ends ?? 'Data não disponível');
      return;
    }

    if(!entity?.identifier) {
      return;
    }
  
    try {
      const response = await requests.tripEstimatedEnds(identifier);
      setEstimatedEnds(response?.date ?? 'Data não disponível');
      setEstimatedEndsTooltip(response?.message ?? 'Data esperada');
    } catch (error) {
      console.error('Erro ao buscar a data estimada de chegada:', error);
    }
  };

  useEffect(() => {
    fetchEstimatedEnds(entity?.identifier, entity?.status, entity?.endsAtFormatted);
  }, [entity]);

  return (
    <TabPanel p='0px'>
      <Table className="table-striped">
        <Tbody>
          <Tr>
            <Td style={{ color: '#854FFF', fontSize: 14, verticalAlign: 'top' }}>Empresas vinculadas</Td>

            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.clientsFormatted?.length === 0 ? (
                  'Nenhuma empresa vinculada'
                ) : (
                  <List spacing={3}>
                    {entity?.clientsFormatted?.map((item, key) => {
                      return <ListItem key={key}>{item}</ListItem>;
                    })}
                  </List>
                )}
              </Flex>
            </Td>
          </Tr>

          {entity?.processesFiltered?.length > 0 && (
            <Tr>
              <Td style={{ color: '#854FFF', fontSize: 14, verticalAlign: 'top' }}>Processos</Td>

              <Td color="#0F0A1DB3" fontSize={14}>
                <Flex alignItems="flex-start" direction="column">
                  <List spacing={3}>
                    {entity?.processesFiltered?.map((item, key) => {
                      return (
                        <ListItem key={key}>
                          {' '}
                          <Text
                            textColor={hasPermission ? 'linkColor' : 'textColor3'}
                            title={hasPermission ? 'Visualizar detalhe do processo' : 'Número do processo'}
                            cursor={hasPermission ? 'pointer' : 'textColor3'}
                            onClick={(decision) => {
                              if (decision && hasPermission) {
                                navigate(`/comex/process/detail/${item?.identifier}`);
                              }
                            }}>
                            {item?.code}
                          </Text>
                        </ListItem>
                      );
                    })}
                  </List>
                </Flex>
              </Td>
            </Tr>
          )}

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Tipo da viagem
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.typeTransport ? typeTransportTitle(entity.typeTransport) : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Modalidade
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.modality ? modalityTitle(entity.modality) : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Transportadora
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.shippingCompanyName ? entity.shippingCompanyName : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Tipo de documento mandatário da viagem
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.typeMandatoryFile ? entity.typeMandatoryFile : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Valor da mercadoria
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.merchandiseValueFormatted ? entity.merchandiseValueFormatted : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Valor do frete
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.freightValueFormatted ? entity.freightValueFormatted : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Peso
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.weightFormatted ? entity.weightFormatted : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Valor mercadoria avariada
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.merchandiseDamagedValueFormatted ? entity.merchandiseDamagedValueFormatted : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Cubagem da carga
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.cubageFormatted ? entity.cubageFormatted : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Código Externo
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.externalCode
                  ? `${entity.externalCode}
                  ${entity.typeExternalCode ? ` (${entity.typeExternalCode})` : ''}`
                  : '-'}
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TabPanel>
  );
};

export default InformationTab;
