import React, { useEffect } from 'react';

import { FaCopy, FaMailBulk } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Button, Flex, ListItem, OrderedList, Text, UnorderedList, useDisclosure } from '@chakra-ui/react';

import { CommonModal } from '../../../../components/CommonModal/CommonModal';
import requests from '../../../../services/requests';

const SignatureResult = ({ userData }) => {
  const currentDateTime = new Date();
  const timestamp = currentDateTime.getTime();

  const { isOpen: modalInfoIsOpen, onOpen: modalInfoOnOpen, onClose: modalInfoOnClose } = useDisclosure();

  const safeUserData = {
    name: userData?.name || '',
    role: userData?.role || '',
    email: userData?.email || '',
    phone: userData?.phone || '',
    address: userData?.address || '',
  };

  const copyHtmlFromUrl = async () => {
    try {
      // Chama a função para gerar a URL dinâmica
      const htmlContent = await requests.generateHtmlSignature(safeUserData);

      if (!htmlContent) {
        throw new Error('Nenhum HTML retornado');
      }

      await navigator.clipboard.writeText(htmlContent); // Copia para a área de transferência
      toast.success('HTML copiado com sucesso!');
    } catch (error) {
      toast.error('Erro ao copiar HTML.');
      console.error('Erro ao buscar o HTML:', error);
    }
  };

  const copyHtmlFromIframe = (htmlContent) => {
    try {
      navigator.clipboard.writeText(htmlContent); // Copia o conteúdo para a área de transferência
      toast.success('HTML copiado com sucesso!');
    } catch (error) {
      toast.error('Erro ao copiar HTML.');
      console.error('Erro ao acessar o conteúdo do iframe:', error);
    }
  };

  // Adiciona o listener para mensagens do iframe
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== 'https://assinaturas.vendemmia.com.br') {
        return; // Ignora mensagens de fontes não confiáveis
      }

      // O iframe envia o HTML para a página principal
      const htmlContent = event.data;
      copyHtmlFromIframe(htmlContent); // Agora copia o HTML do iframe via postMessage
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Flex>
      <Flex direction="column" w="full">
        <Flex direction="column" w="full" bgColor="#FFFFFF">
          <iframe
            id="iframe"
            width="100%"
            style={{ height: '45vh' }}
            src={`https://assinaturas.vendemmia.com.br/v2/generate.php?name=${encodeURIComponent(
              safeUserData.name
            )}&description=${encodeURIComponent(safeUserData.role)}&email=${encodeURIComponent(
              safeUserData.email
            )}&phone=${encodeURIComponent(safeUserData.phone)}&address=${encodeURIComponent(safeUserData.address)}&v=${timestamp}`}
          />
        </Flex>

        <Flex
          w="full"
          mt={{ sm: '20px', lg: '30px' }}
          gap={{ sm: '0px', lg: '15px' }}
          justify={{ sm: 'space-between', lg: 'end' }}
          className="down-anim">
          <Button
            variant="solid"
            height="40px"
            borderRadius="5px"
            bgColor="#FF2F69"
            textColor="#FFFFFF"
            transition=".3s"
            _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
            onClick={modalInfoOnOpen}>
            <FaMailBulk />
            <Text ml="10px">Como configuro?</Text>
          </Button>

          <Button
            variant="outline"
            height="40px"
            borderRadius="5px"
            borderColor="#FF2F69"
            textColor="#FF2F69"
            transition=".3s"
            _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
            onClick={copyHtmlFromUrl}>
            <FaCopy />
            <Text ml="10px">Copiar HTML</Text>
          </Button>
        </Flex>
      </Flex>

      {/* Information */}
      <CommonModal
        heading={'Como configurar sua assinatura'}
        isOpen={modalInfoIsOpen}
        onOpen={modalInfoOnOpen}
        onClose={modalInfoOnClose}
        size={'4xl'}
        removeRadius={true}>
        <OrderedList>
          <ListItem>
            Selecione com o <kbd>mouse</kbd> todo o conteúdo da assinatura gerada ou clique dentro da caixa com a assinatura e aperte
            as teclas <kbd>ctrl + a</kbd>.
          </ListItem>

          <ListItem>
            Copie o conteúdo selecionado (com as teclas <kbd>ctrl + c</kbd>, ou com o botão direito do mouse e clicando em copiar).
          </ListItem>

          <ListItem>
            Escolha uma das opções abaixo para aplicar sua assinatura:
            <UnorderedList styleType="lower-alpha">
              <ListItem>
                Se você estiver com o <b>outlook instalado</b> no computador
                <UnorderedList>
                  <ListItem>
                    Acesse, no canto superior esquerdo, {'Arquivo'} {'>'} {'Opções'} {'>'} {'E-mail'} {'>'} {'Assinatura'}.
                  </ListItem>
                  <ListItem>Preencha o espaço da assinatura colando o que foi copiado.</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Se você usa a <b>versão online</b>, no navegador
                <UnorderedList>
                  <ListItem>Acesse, no canto superior direito a engrenagem de configurações</ListItem>
                  <ListItem>Use a caixa de pesquisa para pesquisar {'Assinatura'}</ListItem>
                  <ListItem>Abra o item {'Assinatura de Email'}</ListItem>
                  <ListItem>Preencha o espaço da assinatura colando o que foi copiado.</ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <b>Importante:</b> Se tiver que corrigir alguma informação, não altere direto em seu e-mail. Corrija pelo formulário da
            página anterior e gere novamente a sua assinatura.
          </ListItem>
        </OrderedList>
      </CommonModal>
    </Flex>
  );
};

export default SignatureResult;
