import React, { useEffect, useState } from 'react';

import { Flex } from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';

import KanbanColumn from './KanbanColumn';

const TravelKanban = ({ filterSelected, kanbanTab, tabOpt }) => {
  const [action, setAction] = useState(false);

  const [loadPreparation, setLoadPreparation] = useState(true);
  const [loadExecution, setLoadExecution] = useState(true);
  const [loadDelivery, setLoadDelivery] = useState(true);
  const [loadInvoicing, setLoadInvoicing] = useState(true);

  const [isLoadingItems, setIsLoadingItems] = useState(false);

  // Preparação
  const [refusedByDriver, setRefusedByDriver] = useState();
  const [draft, setDraft] = useState();
  const [created, setCreated] = useState();
  const [sentToDriver, setSentToDriver] = useState();
  const [acceptedByDriver, setAcceptedByDriver] = useState();

  const [metaRefusedByDriver, setMetaRefusedByDriver] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDraft, setMetaDraft] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCreated, setMetaCreated] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaSentToDriver, setMetaSentToDriver] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaAcceptedByDriver, setMetaAcceptedByDriver] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  //Execução
  const [goingToCollect, setGoingToCollect] = useState();
  const [readToCollect, setReadToCollect] = useState();
  const [collecting, setCollecting] = useState();
  const [readToTravel, setReadToTravel] = useState();
  const [travelling, setTravelling] = useState();
  const [halted, setHalted] = useState();

  const [metaGoingToCollect, setMetaGoingToCollect] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaReadToCollect, setMetaReadToCollect] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCollecting, setMetaCollecting] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaReadToTravel, setMetaReadToTravel] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaTravelling, setMetaTravelling] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaHalted, setMetaHalted] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  //Entrega
  const [arrivedAtDestination, setArrivedAtDestination] = useState();
  const [unloading, setUnloading] = useState();
  const [deliverRefused, setDeliverRefused] = useState();
  const [deliverSignatureCollected, setDeliverSignatureCollected] = useState();
  const [deliverSignatureRefused, setDeliverSignatureRefused] = useState();
  const [completed, setCompleted] = useState();
  const [haltedHandled, setHaltedHandled] = useState();

  const [metaArrivedAtDestination, setMetaArrivedAtDestination] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaUnloading, setMetaUnloading] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDeliverRefused, setMetaDeliverRefused] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDeliverSignatureCollected, setMetaDeliverSignatureCollected] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDeliverSignatureRefused, setMetaDeliverSignatureRefused] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCompleted, setMetaCompleted] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaHaltedHandled, setMetaHaltedHandled] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  //Faturamento
  const [paymentAllowed, setPaymentAllowed] = useState();
  const [paid, setPaid] = useState();
  const [paidCancelled, setPaidCancelled] = useState();

  const [metaPaymentAllowed, setMetaPaymentAllowed] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaPaid, setMetaPaid] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaPaidCancelled, setMetaPaidCancelled] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  // Preparação
  const showMoreItems = async (requestFunction, stateSetter, state, metaSetter, meta) => {
    setIsLoadingItems(true);
    try {
      const response = await requestFunction(meta.current_page > 0 ? meta.current_page + 1 : 1, 10, filterSelected);

      stateSetter([[...state[0], ...response.data]]);
      metaSetter(response.meta);
      setIsLoadingItems(false);
    } catch (error) {
      setIsLoadingItems(false);
      console.error('Erro na solicitação da API: ', error);
    }
  };

  const showMoreRefusedByDriver = () => {
    showMoreItems(requests.requestRefusedByDriver, setRefusedByDriver, refusedByDriver, setMetaRefusedByDriver, metaRefusedByDriver);
  };

  const showMoreDraft = () => {
    showMoreItems(requests.requestDraft, setDraft, draft, setMetaDraft, metaDraft);
  };

  const showMoreCreated = () => {
    showMoreItems(requests.requestCreated, setCreated, created, setMetaCreated, metaCreated);
  };

  const showMoreSentToDriver = () => {
    showMoreItems(requests.requestSentToDriver, setSentToDriver, sentToDriver, setMetaSentToDriver, metaSentToDriver);
  };

  const showMoreAcceptedByDriver = () => {
    showMoreItems(
      requests.requestAcceptedByDriver,
      setAcceptedByDriver,
      acceptedByDriver,
      setMetaAcceptedByDriver,
      metaAcceptedByDriver
    );
  };

  const showMoreHalted = () => {
    showMoreItems(requests.requestHalted, setHalted, halted, setMetaHalted, metaHalted);
  };

  //Execução
  const showMoreGoingToCollect = () => {
    showMoreItems(requests.requestGoingToCollect, setGoingToCollect, goingToCollect, setMetaGoingToCollect, metaGoingToCollect);
  };

  const showMoreReadToCollect = () => {
    showMoreItems(requests.requestReadToCollect, setReadToCollect, readToCollect, setMetaReadToCollect, metaReadToCollect);
  };

  const showMoreCollecting = () => {
    showMoreItems(requests.requestCollecting, setCollecting, collecting, setMetaCollecting, metaCollecting);
  };

  const showMoreReadToTravel = () => {
    showMoreItems(requests.requestReadToTravel, setReadToTravel, readToTravel, setMetaReadToTravel, metaReadToTravel);
  };

  const showMoreTravelling = () => {
    showMoreItems(requests.requestTravelling, setTravelling, travelling, setMetaTravelling, metaTravelling);
  };

  const showMoreHaltedHandled = () => {
    showMoreItems(requests.requestHaltedHandled, setHaltedHandled, haltedHandled, setMetaHaltedHandled, metaHaltedHandled);
  };

  //Entrega
  const showMoreArrivedAtDestination = () => {
    showMoreItems(
      requests.requestArrivedAtDestination,
      setArrivedAtDestination,
      arrivedAtDestination,
      setMetaArrivedAtDestination,
      metaArrivedAtDestination
    );
  };

  const showMoreUnloading = () => {
    showMoreItems(requests.requestUnloading, setUnloading, unloading, setMetaUnloading, metaUnloading);
  };

  const showMoreDeliverRefused = () => {
    showMoreItems(requests.requestDeliverRefused, setDeliverRefused, deliverRefused, setMetaDeliverRefused, metaDeliverRefused);
  };

  const showMoreDeliverSignatureCollected = () => {
    showMoreItems(
      requests.requestDeliverSignatureCollected,
      setDeliverSignatureCollected,
      deliverSignatureCollected,
      setMetaDeliverSignatureCollected,
      metaDeliverSignatureCollected
    );
  };

  const showMoreDeliverSignatureRefused = () => {
    showMoreItems(
      requests.requestDeliverSignatureRefused,
      setDeliverSignatureRefused,
      deliverSignatureRefused,
      setMetaDeliverSignatureRefused,
      metaDeliverSignatureRefused
    );
  };

  const showMoreCompleted = () => {
    showMoreItems(requests.requestCompleted, setCompleted, completed, setMetaCompleted, metaCompleted);
  };

  //Faturamento
  const showMorePaymentAllowed = () => {
    showMoreItems(requests.requestPaymentAllowed, setPaymentAllowed, paymentAllowed, setMetaPaymentAllowed, metaPaymentAllowed);
  };

  const showMorePaid = () => {
    showMoreItems(requests.requestPaid, setPaid, paid, setMetaPaid, metaPaid);
  };

  const showMorePaidCancelled = () => {
    showMoreItems(requests.requestPaidCancelled, setPaidCancelled, paidCancelled, setMetaPaidCancelled, metaPaidCancelled);
  };

  const fetchData = async (requestFunction, setStateFunction, setMetaFunction, currentPage, pageSize) => {
    if (filterSelected.period) {
      try {
        const response = await requestFunction(currentPage > 0 ? currentPage : 1, pageSize, filterSelected);
        setStateFunction([response.data]);
        setMetaFunction(response.meta);

        switch (kanbanTab) {
          case 'Preparação':
            return setLoadPreparation(false);

          case 'Execução':
            return setLoadExecution(false);

          case 'Entrega':
            return setLoadDelivery(false);

          case 'Faturamento':
            return setLoadInvoicing(false);

          default:
            return api;
        }
      } catch (error) {
        console.error('Erro na solicitação da API: ', error);
      }
    }
  };

  const kanbanColumnsPreparation = [
    {
      title: 'Recusado pelo motorista',
      status: 'REFUSED_BY_DRIVER_HANDLED',
      icon: 'trip-refused.svg',
      color: '#E74C3C',
      entity: refusedByDriver,
      meta: metaRefusedByDriver,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreRefusedByDriver,
    },
    {
      title: 'Rascunho',
      status: 'DRAFT',
      icon: 'trip-draft.svg',
      color: '#6C48C2',
      entity: draft,
      meta: metaDraft,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreDraft,
    },
    {
      title: 'Necessário retribuição',
      status: 'HALTED',
      icon: 'trip-halted.svg',
      color: '#6C48C2',
      entity: halted,
      meta: metaHalted,
      setLoad: setLoadExecution,
      showMoreItems: showMoreHalted,
    },
    {
      title: 'Cadastrada',
      status: 'CREATED',
      icon: 'trip-created.svg',
      color: '#6C48C2',
      entity: created,
      meta: metaCreated,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreCreated,
    },
    {
      title: 'Aguardando motorista',
      status: 'SENT_TO_DRIVER',
      icon: 'trip-sent.svg',
      color: '#6C48C2',
      entity: sentToDriver,
      meta: metaSentToDriver,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreSentToDriver,
    },
    {
      title: 'Agendada',
      status: 'ACCEPTED_BY_DRIVER',
      icon: 'trip-accepted.svg',
      color: '#2ECC71',
      entity: acceptedByDriver,
      meta: metaAcceptedByDriver,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreAcceptedByDriver,
    },
    // {
    //   title: 'Cancelada',
    //   status: 'CANCELLED',
    //   icon: 'trip-cancelled.svg',
    //   color: '#E74C3C',
    //   entity: cancelled,
    //   meta: metaCancelled,
    //   setLoad: setLoadPreparation,
    //   showMoreItems: showMoreCancelled,
    // },
  ];

  const kanbanColumnsExecution = [
    {
      title: 'Indo para coleta',
      status: 'GOING_TO_COLLECT',
      icon: 'trip-going.svg',
      color: '#6C48C2',
      entity: goingToCollect,
      meta: metaGoingToCollect,
      setLoad: setLoadExecution,
      showMoreItems: showMoreGoingToCollect,
    },
    {
      title: 'No pátio para coleta',
      status: 'READ_TO_COLLECT',
      icon: 'trip-collect.svg',
      color: '#6C48C2',
      entity: readToCollect,
      meta: metaReadToCollect,
      setLoad: setLoadExecution,
      showMoreItems: showMoreReadToCollect,
    },
    {
      title: 'Coleta iniciada',
      status: 'COLLECTING',
      icon: 'trip-collecting.svg',
      color: '#6C48C2',
      entity: collecting,
      meta: metaCollecting,
      setLoad: setLoadExecution,
      showMoreItems: showMoreCollecting,
    },
    {
      title: 'Veículo carregado',
      status: 'READ_TO_TRAVEL',
      icon: 'trip-travel.svg',
      color: '#6C48C2',
      entity: readToTravel,
      meta: metaReadToTravel,
      setLoad: setLoadExecution,
      showMoreItems: showMoreReadToTravel,
    },
    {
      title: 'Em viagem',
      status: 'TRAVELLING',
      icon: 'trip-travelling.svg',
      color: '#6C48C2',
      entity: travelling,
      meta: metaTravelling,
      setLoad: setLoadExecution,
      showMoreItems: showMoreTravelling,
    },
    {
      title: 'Impedido',
      status: 'HALTED_HANDLED',
      icon: 'trip-halted.svg',
      color: '#6C48C2',
      entity: haltedHandled,
      meta: metaHaltedHandled,
      setLoad: setLoadExecution,
      showMoreItems: showMoreHaltedHandled,
    },
  ];

  const kanbanColumnsDelivery = [
    {
      title: 'No pátio para descarga',
      status: 'ARRIVED_AT_DESTINATION',
      icon: 'trip-arrived.svg',
      color: '#6C48C2',
      entity: arrivedAtDestination,
      meta: metaArrivedAtDestination,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreArrivedAtDestination,
    },
    {
      title: 'Descarregando',
      status: 'UNLOADING',
      icon: 'trip-unloading.svg',
      color: '#6C48C2',
      entity: unloading,
      meta: metaUnloading,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreUnloading,
    },
    {
      title: 'Entrega recusada',
      status: 'DELIVER_REFUSED',
      icon: 'trip-deliver.svg',
      color: '#6C48C2',
      entity: deliverRefused,
      meta: metaDeliverRefused,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreDeliverRefused,
    },
    {
      title: 'Entregue sem assinatura',
      status: 'DELIVERED_WITHOUT_SIGNATURE',
      icon: 'trip-signature-off.svg',
      color: '#6C48C2',
      entity: deliverSignatureCollected,
      meta: metaDeliverSignatureCollected,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreDeliverSignatureCollected,
    },
    {
      title: 'Entregue com assinatura',
      status: 'DELIVERED_WITH_SIGNATURE',
      icon: 'trip-signature.svg',
      color: '#6C48C2',
      entity: deliverSignatureRefused,
      meta: metaDeliverSignatureRefused,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreDeliverSignatureRefused,
    },
    {
      title: 'Finalizada',
      status: 'COMPLETED',
      icon: 'trip-completed.svg',
      color: '#6C48C2',
      entity: completed,
      meta: metaCompleted,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreCompleted,
    },
  ];

  const kanbanColumnsInvoicing = [
    {
      title: 'Pagamento liberado',
      status: 'PAYMENT_ALLOWED',
      icon: 'trip-payment.svg',
      color: '#6C48C2',
      entity: paymentAllowed,
      meta: metaPaymentAllowed,
      setLoad: setLoadInvoicing,
      showMoreItems: showMorePaymentAllowed,
    },
    {
      title: 'Pagamento efetuado',
      status: 'PAID',
      icon: 'trip-paid.svg',
      color: '#6C48C2',
      entity: paid,
      meta: metaPaid,
      setLoad: setLoadInvoicing,
      showMoreItems: showMorePaid,
    },
    {
      title: 'Pagamento cancelado',
      status: 'PAID_CANCELLED',
      icon: 'trip-payment-off.svg',
      color: '#6C48C2',
      entity: paidCancelled,
      meta: metaPaidCancelled,
      setLoad: setLoadInvoicing,
      showMoreItems: showMorePaidCancelled,
    },
  ];

  const kanbanColumns = {
    Preparação: kanbanColumnsPreparation,
    Execução: kanbanColumnsExecution,
    Entrega: kanbanColumnsDelivery,
    Faturamento: kanbanColumnsInvoicing,
  };

  const fetchKanbanTabData = async () => {
    const fetchDataForTab = (requestsArray) => {
      requestsArray.forEach((request) => {
        fetchData(request.api, request.setter, request.metaSetter, request.currentPage, 10);
      });
    };

    try {
      const tabDataMap = {
        Preparação: [
          {
            api: requests.requestRefusedByDriver,
            setter: setRefusedByDriver,
            metaSetter: setMetaRefusedByDriver,
            currentPage: metaRefusedByDriver.current_page,
          },
          { api: requests.requestDraft, setter: setDraft, metaSetter: setMetaDraft, currentPage: metaDraft.current_page },
          { api: requests.requestHalted, setter: setHalted, metaSetter: setMetaHalted, currentPage: metaHalted.current_page },
          { api: requests.requestCreated, setter: setCreated, metaSetter: setMetaCreated, currentPage: metaCreated.current_page },
          {
            api: requests.requestSentToDriver,
            setter: setSentToDriver,
            metaSetter: setMetaSentToDriver,
            currentPage: metaSentToDriver.current_page,
          },
          {
            api: requests.requestAcceptedByDriver,
            setter: setAcceptedByDriver,
            metaSetter: setMetaAcceptedByDriver,
            currentPage: metaAcceptedByDriver.current_page,
          },
        ],
        Execução: [
          {
            api: requests.requestGoingToCollect,
            setter: setGoingToCollect,
            metaSetter: setMetaGoingToCollect,
            currentPage: metaGoingToCollect.current_page,
          },
          {
            api: requests.requestReadToCollect,
            setter: setReadToCollect,
            metaSetter: setMetaReadToCollect,
            currentPage: metaReadToCollect.current_page,
          },
          {
            api: requests.requestCollecting,
            setter: setCollecting,
            metaSetter: setMetaCollecting,
            currentPage: metaCollecting.current_page,
          },
          {
            api: requests.requestReadToTravel,
            setter: setReadToTravel,
            metaSetter: setMetaReadToTravel,
            currentPage: metaReadToTravel.current_page,
          },
          {
            api: requests.requestTravelling,
            setter: setTravelling,
            metaSetter: setMetaTravelling,
            currentPage: metaTravelling.current_page,
          },
          {
            api: requests.requestHaltedHandled,
            setter: setHaltedHandled,
            metaSetter: setMetaHaltedHandled,
            currentPage: metaHaltedHandled.current_page,
          },
        ],
        Entrega: [
          {
            api: requests.requestArrivedAtDestination,
            setter: setArrivedAtDestination,
            metaSetter: setMetaArrivedAtDestination,
            currentPage: metaArrivedAtDestination.current_page,
          },
          {
            api: requests.requestUnloading,
            setter: setUnloading,
            metaSetter: setMetaUnloading,
            currentPage: metaUnloading.current_page,
          },
          {
            api: requests.requestDeliverRefused,
            setter: setDeliverRefused,
            metaSetter: setMetaDeliverRefused,
            currentPage: metaDeliverRefused.current_page,
          },
          {
            api: requests.requestDeliverSignatureCollected,
            setter: setDeliverSignatureCollected,
            metaSetter: setMetaDeliverSignatureCollected,
            currentPage: metaDeliverSignatureCollected.current_page,
          },
          {
            api: requests.requestDeliverSignatureRefused,
            setter: setDeliverSignatureRefused,
            metaSetter: setMetaDeliverSignatureRefused,
            currentPage: metaDeliverSignatureRefused.current_page,
          },
          {
            api: requests.requestCompleted,
            setter: setCompleted,
            metaSetter: setMetaCompleted,
            currentPage: metaCompleted.current_page,
          },
        ],
        Faturamento: [
          {
            api: requests.requestPaymentAllowed,
            setter: setPaymentAllowed,
            metaSetter: setMetaPaymentAllowed,
            currentPage: metaPaymentAllowed.current_page,
          },
          { api: requests.requestPaid, setter: setPaid, metaSetter: setMetaPaid, currentPage: metaPaid.current_page },
          {
            api: requests.requestPaidCancelled,
            setter: setPaidCancelled,
            metaSetter: setMetaPaidCancelled,
            currentPage: metaPaidCancelled.current_page,
          },
        ],
      };

      const requestsArray = tabDataMap[kanbanTab];
      if (requestsArray) {
        fetchDataForTab(requestsArray);
      }
    } catch (error) {
      console.error('Erro na solicitação da API: ', error);
    }
  };

  useEffect(() => {
    fetchKanbanTabData();
  }, [filterSelected, kanbanTab]);

  const renderKanbanColumns = (column, index) => (
    <KanbanColumn
      key={index}
      {...column}
      tab={kanbanTab}
      update={action}
      setUpdate={setAction}
      isLoadingItems={isLoadingItems}
      reloadList={fetchKanbanTabData}
    />
  );

  return (
    <>
      <ScreenLoader
        overflowX="auto"
        isLoading={
          {
            Preparação: loadPreparation,
            Execução: loadExecution,
            Entrega: loadDelivery,
            Faturamento: loadInvoicing,
          }[kanbanTab]
        }>
          {tabOpt
            .filter((tabName) => kanbanTab === tabName.title)
            .map((tabName, index) => (
              <Flex h='full' w="full" key={index}>
                {kanbanColumns[tabName.title]?.map((column, idx) => renderKanbanColumns(column, idx))}
              </Flex>
            ))}
      </ScreenLoader>
    </>
  );
};

export default TravelKanban;
