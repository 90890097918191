import React, { useEffect, useState } from 'react';

import { Flex, Table, Tbody, Thead, Tr } from '@chakra-ui/react';

import Paginate from '../../../../../components/Paginate/Paginate';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';

import TableListCte from './TableListCte';

const TripCte = ({ tab, filterSelected, setTotalCte }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);

  const [metadata, setMetadata] = useState({});
  const [action, setAction] = useState(1);

  const listCtes = (filterSelected) => {
    setIsLoading(true);

    requests
      .listCtes(filterSelected, metadata?.current_page > 0 ? metadata?.current_page : 1)
      .then((data) => {
        setList(data?.data);
        setMetadata(data?.meta);

        setTotalCte(data?.meta.total_count);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    listCtes(filterSelected);
  }, [filterSelected, action]);

  return (
    <>
      <ScreenLoader isLoading={isLoading} overflowY="auto">
        <Table w="full">
          <Thead>
            <Tr></Tr>
          </Thead>

          <Tbody>
            {list?.map((entity, key) => (
              <TableListCte tab={tab} entity={entity} setIsLoading={setIsLoading} key={key} />
            ))}
          </Tbody>
        </Table>

        {isLoading === false && list?.length === 0 && (
          <Flex justify="center" my="25px">
            Nenhum dado encontrado para os parâmetros filtrados.
          </Flex>
        )}
      </ScreenLoader>

      {metadata?.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default TripCte;
