import React, { useRef, useState } from 'react';

import { Flex, Modal, ModalBody, ModalContent } from '@chakra-ui/react';

import MapRegisterFreight from '../../../../../components/Map/MapRegisterFreight';

import RegisterFreight from './RegisterFreight';

const ModalFreight = ({ entity, isOpen, onClose, title, subTitle, reloadList = () => {} }) => {
  const [geolocationList, setGeolocationList] = useState({});

  const mapContainerRef = useRef(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent h="calc(100vh - 90px)" maxW="none" top={0} borderRadius={'0'} position={'absolute'}>
        <ModalBody bgColor="#FAFBFF" p="0" display="flex" flexDirection={{ sm: 'column', md: 'row' }}>
          <RegisterFreight
            entity={entity}
            title={title}
            subTitle={subTitle}
            onCancelled={onClose}
            geolocationList={geolocationList}
            setGeolocationList={setGeolocationList}
            order={{ sm: '2', md: 'initial' }}
            reloadList={reloadList}
          />

          <Flex ref={mapContainerRef} width="100%" overflow="hidden" order={{ sm: '1', md: 'initial' }}>
            <MapRegisterFreight w="100%" h="100%" points={geolocationList} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalFreight;
