import React, { useState } from 'react';

import { MdDownloadForOffline } from 'react-icons/md';

import { Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

/**
 *
 * @param downloadAction Função ao clicar no botão
 * @param title Texto apresentado no botão
 * @param text Texto apresentado no modal
 */

const ModalCSV = ({
  title = 'Baixar .csv',
  text = 'Exportar CSV',
  downloadButtonText = 'Baixar',
  downloadAction = () => {},
  filters = null,
  filtersKey = null,
  setIsOpenFloatButton = () => {},
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

  const downloadCsv = () => {
    setIsDownloadingCsv(true);
    downloadAction(filters, filtersKey);
    setIsDownloadingCsv(false);
    onClose();
  };

  return (
    <>
      <Button
        h="50px"
        bg="green"
        borderRadius="50px"
        color="white"
        _hover={{ opacity: '0.8' }}
        leftIcon={<MdDownloadForOffline color="#FFFFFF" size={20} />}
        onClick={() => {
          setIsOpenFloatButton(false);
          onOpen();
        }}
        {...props}>
        {title}
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />

        <ModalContent borderRadius="6px">
          <ModalBody p="20px 15px">
            <Flex direction="column" alignItems="center" justifyContent="center">
              <Text fontWeight="medium" textColor="#0F0A1D" textAlign="center" mb="20px" className="up-anim">
                {text}
              </Text>

              <Button
                variant="primary"
                w="fit-content"
                borderRadius="6px"
                p="15px 25px"
                loadingText="Carregando..."
                isLoading={isDownloadingCsv}
                onClick={downloadCsv}>
                {downloadButtonText}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCSV;
