import { useEffect, useState } from 'react';

import { MdAdd, MdHistory } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import formatDocument from '../../../utils/fields/masks/formatDocument';
import { filterOptionsTaxInquiry } from '../../../utils/filters/filterPresets';

import { commonListConfig } from './components/commonListConfig';
import TaxInquiryHistoryDrawer from './components/TaxInquiryHistoryDrawer';

const TaxInquiryPage = () => {
  const hasPermission = permissions.vendemmiaTaxInquiry;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isMobile, setIsMobile] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const load = (filters, key) => {
    fetchTaxInquiryListData(filters, metadata.current_page, 25);
  };

  const fetchTaxInquiryListData = async (filters, page, pageSize) => {
    setIsLoading(true);
    await requests.fetchTaxInquiryList(filters, page, pageSize).then((data) => {
      let options = [];
      data.data.map((tax) => {
        return options.push({
          identifier: tax.identifier,
          code: tax.code ?? '-',
          companyBranchName: tax.companyBranchName ?? '-',
          clientDocumentNumber: tax?.clientDocumentNumber != null ? formatDocument(tax.clientDocumentNumber) : '-',
          importModality: tax.importModalityReadable ?? '-',
          taxBenefit: tax.taxBenefitReadable ?? '-',
          requestedAt: tax?.requestedAtFormatted,
          expiresAt: tax?.expiresAtFormatted ?? '-',
          status: tax.statusReadable,
          createdAtFormatted: tax?.createdAtFormatted ?? '-',
        });
      });
      setList(options);
      setMetadata(data.meta);
      setIsLoading(false);
    });
  };

  const exportListFABActionCSV = (filters, key) => {
    //preset
    const extension = `csv`;
    const endpoint = `/tax-inquiry/export/${extension}`;
    const fileName = `taxs`;
    const method = `GET`;
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportListFABActionExcel = (filters, key) => {
    //preset
    const extension = `xlsx`;
    const endpoint = `/tax-inquiry/export/${extension}`;
    const fileName = `taxs`;
    const method = `GET`;
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const openFABActionHistory = () => {
    onOpen();
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsTaxInquiry());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    load();
  }, [action]);

  return (
    <Page
      screen="tax-inquiry"
      title="Consulta tributária"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Nome e CNPJ do Cliente"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      sorting={sorting}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showRefreshData={false}
      showFilters={true}
      allowFiltersOverride={false}
      FAB={[
        {
          title: 'Cadastrar consulta tributária',
          text: 'Cadastro de consulta tributária',
          action: (
            <Link to="/vendemmia/tax-inquiry/new" key={2}>
              <Button
                leftIcon={<MdAdd color="#FFFFFF" size={20} />}
                _hover={{ bgColor: '#70D499' }}
                bg="green"
                p="1.5rem"
                pb="1.5rem"
                pl="1.3rem"
                pr="1.3rem"
                borderRadius="50px">
                <Text mr="10px" color="white">
                  Novo estudo
                </Text>
              </Button>
            </Link>
          ),
          modality: 'custom',
        },
        {
          title: 'Baixar relatório em (.csv)',
          text: 'Baixar relatório de consulta tributária (.csv)',
          action: exportListFABActionCSV,
          modality: 'export-csv',
        },
        {
          title: 'Baixar relatório em (.xlsx)',
          text: 'Baixar relatório de consulta tributária (.xlsx)',
          action: exportListFABActionExcel,
          modality: 'export-csv',
        },
        {
          title: 'Ver Historico',
          text: 'Consultar histórico da NCM',
          action: (
            <Button
              leftIcon={<MdHistory color="#FFFFFF" size={20} />}
              _hover={{ bgColor: '#70D499' }}
              bg="green"
              p="1.5rem"
              pb="1.5rem"
              pl="1.3rem"
              pr="1.3rem"
              borderRadius="50px"
              onClick={openFABActionHistory}>
              <Text mr="10px" color="white">
                Ver Historico
              </Text>
            </Button>
          ),
          modality: 'custom',
        },
      ]}>
      <Box m={'0 10px'}>
        <CommonList
          //lists
          list={list}
          rawData={list}
          //actions
          action={action}
          sorting={sorting}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          handleSort={handleSort}
          //loading
          isLoading={isLoading}
          //props
          {...commonListConfig}
        />
      </Box>

      {isOpen && <TaxInquiryHistoryDrawer isOpen={isOpen} onClose={onClose} />}
    </Page>
  );
};

export default TaxInquiryPage;
