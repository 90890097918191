import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import FloatActionButton from '../components/Generic/FloatActionButton';
import ModalCSV from '../components/Modal/CSV/ModalCSV';
import ModalUploadWithButton from '../components/Modal/Upload/ModalUploadWithButton';
import useResponsiveCheck from '../hooks/useResponsiveCheck';
import useScreenPage from '../hooks/useScreenPage';

import Layout from './Layout';
import Menubar from './MenuBar/Menubar';
import ScreenLoader from './ScreenLoader/ScreenLoader';

const Page = ({
  children,

  screen,

  setIsMobile,
  setDeviceType,

  title,
  detailsTitle,
  nameForm,
  breadcrumbIcon,
  breadcrumbs,

  hasPermission = true,

  list,
  metadata,

  load,
  isContentLoading = false,
  disableMultiUpload = false,
  disableAdditionalFields = false,

  loadMore,
  isContentLoadingMore = false,

  loadCharts,

  allowFiltersOverride = false,
  filterOptions,
  textFilterPlaceholder,
  isRefreshLoading,
  showRefreshData = true,

  hideAllFilters = false,
  showFilters = true,
  showPeriodFilter = true,
  showTextFilter = true,
  allowEmptySearchPeriod = false,
  allowEmptySearchFilterData = false,
  allowEmptySearchString = false,

  sorting,

  additionalFormFields = [],

  isLoading = false, // Loading para tela inteira

  forceLoadTrigger = null,
  forceSetSearchPeriod = null,

  FAB,

  useOwnState = false,
  ownState,
  setOwnState,

  hasChartChanges,

  refreshSeconds,

  showScreenshot = false,
  refScreen = null,
  ScreenShotIsDisabled = false,
}) => {
  const navigate = useNavigate();

  const mobile = useResponsiveCheck();

  const pageRef = useRef(null);

  const currentPageName = useScreenPage();

  const [pageNumber, setPageNumber] = useState(() => {
    return metadata?.current_page && metadata?.current_page >= 1 ? metadata?.current_page : 1;
  });

  const [paginationHelper, setPaginationHelper] = useState({
    loadedPages: [],
    totalCount: null,
  });

  const [searchString, setSearchString] = useState('');
  const [searchPeriod, setSearchPeriod] = useState();
  const [searchFilterData, setSearchFilterData] = useState(null);
  const [shouldIgnoreFilters, setShouldIgnoreFilters] = useState(false);

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const shouldPreventSearch = () => {
    let allow = false;

    if (
      sorting &&
      ((typeof sorting === 'object' && Object.keys(sorting).length > 0) || (typeof sorting === 'array' && sorting.length > 0))
    ) {
      allow = true;
    }

    if (allowEmptySearchPeriod || (searchPeriod && Object.keys(searchPeriod).length > 0)) {
      allow = true;
    }

    if (allowEmptySearchFilterData || (searchFilterData && Object.keys(searchFilterData).length > 0)) {
      allow = true;
    }

    if (allowEmptySearchString || (searchString && searchString.length > 0)) {
      allow = true;
    }

    return !allow;
  };

  const getFilters = () => ({
    search: searchString,
    searchOveridesFilters: shouldIgnoreFilters,
    period: searchPeriod,
    filters: searchFilterData,
    sort: sorting,
    currentPageName,
  });

  const getFiltersKey = () => moment(new Date()).format('x');

  const onChangePeriod = (date) => setSearchPeriod(date);

  const onChangeFilters = (value) => setSearchFilterData(value);

  const onChangeTextFilter = (value) => {
    let aux = value.trim();
    if (aux.length > 2 || aux.length === 0) {
      setSearchString(aux);
    }
  };

  const onChangeshouldIgnoreFilters = (value) => setShouldIgnoreFilters(value);

  const refresh = () => {
    if (shouldPreventSearch()) {
      return;
    }

    loadCharts && typeof loadCharts === 'function' && loadCharts(getFilters(), getFiltersKey());
    load && typeof load === 'function' && load(getFilters(), getFiltersKey(), pageNumber);
  };

  useEffect(() => {
    if (!hasPermission) {
      navigate('/');

      return;
    }

    typeof setIsMobile === 'function' && setIsMobile(mobile.isMobile);
    typeof setDeviceType === 'function' && setDeviceType(mobile.deviceType);
  }, []);

  useEffect(() => {
    typeof setIsMobile === 'function' && setIsMobile(mobile.isMobile);
    typeof setDeviceType === 'function' && setDeviceType(mobile.deviceType);
  }, [mobile]);

  useEffect(() => {
    const handleScroll = () => {
      return;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pageRef, list]);

  useEffect(() => {
    if (shouldPreventSearch()) {
      return;
    }

    if (typeof loadCharts === 'function') {
      loadCharts(getFilters(), getFiltersKey());
    }

    if (typeof load === 'function') {
      load(getFilters(), getFiltersKey(), pageNumber);
    }
  }, [searchString, searchPeriod, searchFilterData, shouldIgnoreFilters, forceLoadTrigger, pageNumber, hasChartChanges]);

  useEffect(() => {
    if (shouldPreventSearch()) {
      return;
    }

    if (typeof loadMore === 'function') {
      // Não pagina se houver loadMore definido
      return;
    }

    if (typeof load === 'function') {
      // Não deve disparar o load se o sorting não tiver valor definido e a páginação for a primeira
      if ((sorting === undefined || sorting === null) && pageNumber === 1) {
        return;
      }

      load(getFilters(), getFiltersKey(), pageNumber);
    }
  }, [sorting]);

  useEffect(() => {
    if (metadata?.total_count !== paginationHelper.totalCount) {
      setPaginationHelper({
        loadedPages: [metadata?.current_page],
        totalCount: metadata?.total_count,
      });
    } else if (!paginationHelper.loadedPages.includes(metadata?.current_page)) {
      setPaginationHelper({
        loadedPages: [...paginationHelper.loadedPages, metadata?.current_page],
        totalCount: metadata?.total_count,
      });
    }

    if (typeof loadMore === 'function') {
      // Não pagina se houver loadMore definido
      return;
    }

    let newPage = metadata && metadata?.current_page !== 0 ? metadata?.current_page : 1;
    if (newPage !== pageNumber) {
      setPageNumber(newPage);
    }
  }, [metadata]);

  useEffect(() => {
    if (forceSetSearchPeriod) {
      setSearchPeriod(forceSetSearchPeriod);
    }
  }, [forceSetSearchPeriod]);

  useEffect(() => {
    if (isContentLoadingMore) {
      loadMore(getFilters());
    }
  }, [isContentLoadingMore]);

  return (
    <ScreenLoader isLoading={isLoading}>
      <Layout>
        <Menubar
          screen={screen}
          title={title}
          detailsTitle={detailsTitle}
          icon={breadcrumbIcon}
          linkTree={breadcrumbs}
          allowFiltersOverride={allowFiltersOverride}
          shouldIgnoreFilters={shouldIgnoreFilters}
          filterOptions={filterOptions}
          onChangeFilters={onChangeFilters}
          hideAllFilters={hideAllFilters}
          showFilters={showFilters}
          showPeriodFilter={showPeriodFilter}
          showTextFilter={showTextFilter}
          onChangePeriod={onChangePeriod}
          placeholder={textFilterPlaceholder}
          showRefreshData={showRefreshData}
          isRefreshLoading={isRefreshLoading}
          triggerRefreshData={refresh}
          refreshSeconds={refreshSeconds}
          onChangeshouldIgnoreFilters={onChangeshouldIgnoreFilters}
          onChangeTextFilter={onChangeTextFilter}
          refScreen={refScreen}
          ScreenShotIsDisabled={ScreenShotIsDisabled}
          showScreenshot={showScreenshot}
        />
        <div ref={pageRef}>
          {children}

          {FAB && FAB.length > 0 && (
            <FloatActionButton
              options={FAB.map((item, index) => {
                switch (item.modality) {
                  case 'export-csv':
                    return (
                      <ModalCSV
                        key={index}
                        title={item.title}
                        text={item.text}
                        downloadAction={item.action}
                        filters={getFilters()}
                        filtersKey={getFiltersKey()}
                        setIsOpenFloatButton={setIsOpenFloatButton}
                      />
                    );

                  case 'upload-files':
                    return (
                      <ModalUploadWithButton
                        key={index}
                        title={item.title}
                        text={item.text}
                        uploadAction={item.action}
                        setIsOpenFloatButton={setIsOpenFloatButton}
                        useOwnState={useOwnState}
                        ownState={ownState}
                        setOwnState={setOwnState}
                        disableAdditionalFields={disableAdditionalFields}
                        disableMultiUpload={disableMultiUpload}
                        nameForm={nameForm}
                        additional={additionalFormFields}
                      />
                    );

                  default:
                    return item.action;
                }
              })}
              isOpen={isOpenFloatButton}
              setIsOpen={setIsOpenFloatButton}
            />
          )}
        </div>
      </Layout>
    </ScreenLoader>
  );
};

export default Page;
