import { useState } from 'react';

import { Formik } from 'formik';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { CustomSubmitButton } from '../../components/CommonForm/components/CustomSubmitButton';
import ScreenLoader from '../ScreenLoader/ScreenLoader';

import { CommonModalStyles } from './CommonModalStyles';

export const CommonModal = ({
  icon,
  subtitle,
  heading,
  children,
  callback,
  footer,
  buttonName,
  initialValues,
  isOpen: propIsOpen,
  onOpen: propOnOpen,
  onClose: propOnClose,
  hasForm = false,
  variant = 'primary',
  closeButton = true,
  size = 'full',
  fullpage = false,
  loadingText = 'Carregando',
  isLoading,
  ...props
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false);

  const { isOpen: disclosureIsOpen, onOpen: disclosureOnOpen, onClose: disclosureOnClose } = useDisclosure();

  const handleOpen = () => {
    if (propOnOpen) {
      propOnOpen();
    } else {
      disclosureOnOpen();
    }
  };

  const handleClose = () => {
    if (propOnClose) {
      propOnClose();
    } else {
      disclosureOnClose();
    }
  };

  const handleClick = async (userDecision, e) => {
    if (userDecision && callback) {
      setIsLoadingModal(true);

      await callback(e);
    }
    setIsLoadingModal(false);

    handleClose();
  };

  return (
    <>
      <CommonModalStyles />
      <Modal isOpen={propIsOpen || disclosureIsOpen} onClose={handleClose} size={size} isCentered {...props}>
        <ModalContent p="10px 35px" maxH={'90%'} borderRadius={(!fullpage || size !== 'full') && '12px'}>
          <ModalHeader
            borderBottom={(heading || subtitle) && !callback ? '1px solid #dfdfdf' : '0'}
            mb={'20px'}
            p={{ sm: '10px 0 0', md: '10px 15px' }}>
            {heading && !callback && (
              <Flex align="center" gap="12px" mb={'10px'}>
                {icon}
                <Text
                  textStyle="secondaryTitle"
                  fontSize={{ sm: '23px', md: '28px' }}
                  textColor="#0F0A1D"
                  className="up-anim"
                  color="primary">
                  {heading}
                </Text>
              </Flex>
            )}
            {subtitle && !callback && (
              <Text
                font="normal normal medium 20px/26px Hanken Grotesk"
                textStyle="subtitle"
                textColor="#0F0A1D80"
                className="up-anim">
                {subtitle}
              </Text>
            )}
          </ModalHeader>

          {closeButton && <ModalCloseButton />}

          {/* //conditional */}
          {callback && !hasForm && (
            <ModalBody position="relative" overflowY={'auto'} p={{ sm: 0, md: '10px 15px' }}>
              <Flex direction="column" alignItems="center" justifyContent="center">
                <Text
                  mb={'10px'}
                  textStyle="secondaryTitle"
                  fontWeight="medium"
                  textColor="#0F0A1D"
                  textAlign="center"
                  className="up-anim">
                  {heading || 'Você tem certeza que deseja realizar essa alteração?'}
                </Text>

                {subtitle && (
                  <Text
                    mt={'10px'}
                    textStyle="subtitle"
                    fontWeight="medium"
                    textColor="#0F0A1D80"
                    textAlign="center"
                    className="up-anim">
                    {subtitle}
                  </Text>
                )}

                <Flex>
                  <Button
                    variant="secundary"
                    w="fit-content"
                    borderRadius="7px"
                    m="15px"
                    p="25px 35px"
                    onClick={() => handleClick(false)}>
                    Cancelar
                  </Button>

                  <ScreenLoader isLoading={isLoadingModal}>
                    <Button
                      variant={variant}
                      w="fit-content"
                      borderRadius="7px"
                      m="15px"
                      p="25px 35px"
                      loadingText={loadingText}
                      onClick={(e) => handleClick(true, e)}>
                      Continuar
                    </Button>
                  </ScreenLoader>
                </Flex>
              </Flex>
            </ModalBody>
          )}

          {/* //form */}
          {hasForm && (
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                callback && callback(values);
              }}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} style={{ overflow: 'auto' }}>
                  <ModalBody position="relative" overflowY={'auto'} p={{ sm: 0, md: '10px 15px' }}>
                    {children}
                  </ModalBody>

                  <ModalFooter>
                    <CustomSubmitButton name={buttonName} isLoading={isLoading} loadingText={loadingText} />
                  </ModalFooter>
                </form>
              )}
            </Formik>
          )}

          {/* //default */}
          {!hasForm && !callback && (
            <>
              <ModalBody position="relative" overflowY={'auto'} p={{ sm: 0, md: '10px 15px' }}>
                {children}
              </ModalBody>

              <ModalFooter>{footer}</ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
