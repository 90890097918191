import { Button } from '@chakra-ui/button';

export function PanelTabsLink({ tab, tabActive, title, text, role, icon, action }) {
  const profileRole = localStorage.getItem('profile-role');

  return profileRole !== role ? (
    <Button
      variant={tab === tabActive ? 'panelTabsButton' : 'secundary'}
      borderRadius="0"
      p={'0 25px'}
      height={'49px'}
      outline={'none'}
      border={'0 !important'}
      title={title}
      rightIcon={icon}
      onClick={action}>
      {text}
    </Button>
  ) : (
    <></>
  );
}
