import React from 'react';

import { AiFillEdit, AiOutlineRise } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Divider, Flex, Grid, Image, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import lineEnd from '../../../../../assets/svgs/line_end.svg';
import lineStart from '../../../../../assets/svgs/line_start.svg';
import { capitalize } from '../../../../../utils/strings';
import { generateLabelSelect } from '../../../../../utils/translate';
import ModalFreight from '../TravelList/ModalFreight';

const KanbanCard = ({ entity, color, tab, update, setUpdate, columnId, reloadList }) => {
  let navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDragStart = (event) => {
    const data = {
      identifier: entity.identifier,
      columnId: columnId,
      modality: entity.modality,
    };

    event.dataTransfer.setData('application/json', JSON.stringify(data));
  };

  const showEdit = (status) => {
    switch (status) {
      case 'DRAFT':
      case 'CREATED':
      case 'SENT_TO_DRIVER':
      case 'ACCEPTED_BY_DRIVER':
      case 'REFUSED_BY_DRIVER':
      case 'HALTED':
        return true;

      default:
        return false;
    }
  };

  const CustomButton = ({ icon: Icon, label, bgColor = '#6C48C2', hoverColor = '#422C76', onClick, ...props }) => (
    <Button
      bgColor={bgColor}
      color="white"
      borderRadius="8px"
      shadow="xl"
      width="6rem"
      h="26px"
      py="15px"
      px="15px"
      leftIcon={Icon && <Icon />}
      _hover={{ bgColor: hoverColor }}
      onClick={onClick}
      {...props}>
      <Text textStyle="paragraph" fontSize="0.6rem" ml="5px">
        {label}
      </Text>
    </Button>
  );

  return (
    <Flex
      className="item"
      draggable={
        entity?.trajectories?.length < 2
          ? false
          : entity.modality === 'MANUAL' || (tab === 'Preparação' && entity.modality !== 'MANUAL')
      }
      cursor={
        entity?.trajectories?.length < 2
          ? false
          : entity.modality === 'MANUAL' || (tab === 'Preparação' && entity.modality !== 'MANUAL')
          ? 'grab'
          : 'default'
      }
      onDragStart={handleDragStart}>
      <Flex
        direction="column"
        bg="#FFFFFF"
        w="230px"
        minH="200px"
        border="1px solid #E2E8F0"
        borderRadius="8px"
        transition=".3s"
        _hover={{ transform: 'scale(1.05)', boxShadow: '0px 2px 20px #00000044' }}>
        <Box h="4px" bg={color} />

        <Flex w="full" h="full" p="10px" direction="column" gap="5px">
          <Grid templateColumns="2fr 1fr" alignItems="center" gap="5px">
            <Flex justify="start" align="center" gap="3px">
              <Tooltip label="Código da viagem">
                <Flex w="full" bg="purple-800" h="25px" borderRadius="5px" alignItems="center" justifyContent="center">
                  <Text
                    color="#FFFFFF"
                    fontSize="0.7rem"
                    fontWeight="medium"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap">
                    #{entity.code}
                  </Text>
                </Flex>
              </Tooltip>

              {entity.externalCode && (
                <Tooltip label={generateLabelSelect(entity?.typeExternalCode)}>
                  <Flex w="full" bg="purple-300" h="25px" borderRadius="5px" alignItems="center" justifyContent="center">
                    <Text
                      color="#FFFFFF"
                      fontSize="0.7rem"
                      fontWeight="medium"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap">
                      {entity.externalCode}
                    </Text>
                  </Flex>
                </Tooltip>
              )}
            </Flex>

            <Flex justify="end" maxW="90px">
              <Tooltip label={entity?.userDriverName}>
                <Text fontSize="0.7rem" color="#0F0A1D" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {entity?.userDriverName ? capitalize(entity.userDriverName) : 'Sem motorista'}
                </Text>
              </Tooltip>
            </Flex>
          </Grid>

          <Divider borderColor="#E2E8F0" />

          <Grid templateRows="repeat(2, auto)" rowGap="5px" h="full" templateColumns="1fr" justifyContent="space-between">
            {/* Informações de início e final */}
            <Grid templateColumns="auto auto 1fr" columnGap="10px" my="8px">
              {[
                {
                  label: 'Inicio',
                  value: entity?.startsAtFormatted,
                  image: lineStart,
                },
                {
                  label: 'Final',
                  value: entity?.endsAtFormatted,
                  image: lineEnd,
                },
              ].map(({ label, value, image }, index) => (
                <React.Fragment key={index}>
                  <Image src={image} />

                  <Text fontSize="9px" opacity="1" color="#0F0A1D">
                    {label}:
                  </Text>

                  <Tooltip label={value != null ? value : 'Data não definida'}>
                    <Text fontSize="10px" color="#0F0A1D" fontWeight="bold" isTruncated>
                      {value != null ? value : 'Data não definida'}
                    </Text>
                  </Tooltip>
                </React.Fragment>
              ))}
            </Grid>

            <Divider borderColor="#E2E8F0" />

            {/* Informações de origem e destino */}
            <Grid templateColumns="auto auto 1fr" columnGap="10px" my="8px">
              {[
                {
                  label: 'Origem',
                  value: entity?.startingFrom,
                  image: lineStart,
                },
                {
                  label: 'Destino',
                  value: entity?.destinationIn,
                  image: lineEnd,
                },
              ].map(({ label, value, image }, index) => (
                <React.Fragment key={index}>
                  <Image src={image} />

                  <Text fontSize="9px" opacity="1" color="#0F0A1D">
                    {label}:
                  </Text>

                  <Tooltip label={value}>
                    <Text
                      fontSize="10px"
                      color="#0F0A1D"
                      fontWeight="bold"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap">
                      {value}
                    </Text>
                  </Tooltip>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Divider borderColor="#E2E8F0" />

          {entity?.trajectories?.length < 2 && (
            <Text fontSize="10px" color="red" fontWeight="bold">
              Faltam informações de origem e destino na viagem.
            </Text>
          )}

          <Flex align="center" justify="center" mt="5px">
            {showEdit(entity?.status) && (
              <CustomButton
                icon={AiFillEdit}
                label="Editar"
                onClick={() => {
                  onOpen();
                }}
                mr="5px"
              />
            )}

            <CustomButton
              icon={AiOutlineRise}
              label="Detalhes"
              onClick={(event) => {
                const url = `/logistic/trip/detail/${entity.identifier}`;
                const state = { historyBack: '/logistic/trip#kanban' };

                if (event.ctrlKey || event.metaKey) {
                  window.open(url, '_blank');
                } else {
                  navigate(url, { state });
                }
              }}
            />
          </Flex>
        </Flex>
      </Flex>

      <ModalFreight
        entity={entity}
        isOpen={isOpen}
        onClose={onClose}
        title="Edição de viagem"
        subTitle="Você pode salvar as alterações da sua viagem."
        action={update}
        setAction={setUpdate}
        tab={tab}
        reloadList={reloadList}
      />
    </Flex>
  );
};

export default KanbanCard;
