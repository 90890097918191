
import requests from '../../../../../../services/requests';
import { getStatusTransition } from '../../../../../../utils/translate';

export default async function tripStatusChange(currentStatus, targetStatus, identifier, modality) {
  let transition = getStatusTransition(currentStatus, targetStatus, modality);

  if (!transition) {
    throw new Error('Não foi possivel alterar o status da viagem, verifique o fluxograma para alteração de status');
  }

  return await requests.changeStatusDriverFreight(identifier, transition);
}
