import { PanelTabs } from '../../../../components/PanelTabs';

const TravelManagementPanelTabs = ({ tabs = [], tab, setTab, setCurrentTab = null, isKanban = false }) => {
  return (
    <PanelTabs.Root>
      {tabs.map(({ key, title, text, icon, link }) => (
        <PanelTabs.Link
          key={key}
          tab={tab}
          tabActive={key}
          title={title}
          text={text}
          icon={icon}
          action={() => {
            if (isKanban && setCurrentTab) {
              setCurrentTab(key); // Se estamos no kanban, muda a aba de status
            } else {
              setTab(key); // Caso contrário, muda a aba principal
            }
          }}
          link={link}
        />
      ))}
    </PanelTabs.Root>
  );
};

export default TravelManagementPanelTabs;
