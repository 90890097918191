import React, { useState } from 'react';

import { MdHelp, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOpenInNew, MdOutlineListAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, IconButton, Image, Td, Text, Tooltip, Tr } from '@chakra-ui/react';

import iconEfetiva from '../../../../../assets/svgs/origem-efetiva.svg';
import iconEsperada from '../../../../../assets/svgs/origem-esperada.svg';
import { processCheck, processUncheck } from '../../../../../utils/exports';
import { capitalize } from '../../../../../utils/strings';
import {
  convertColorTrip,
  descriptionStatusTrip,
  generateLabelSelect,
  modalityIcon,
  modalityTitle,
  typeTransportTitle,
} from '../../../../../utils/translate';
import DocumentModalView from '../../../CurrentTrips/components/Documents/DocumentModalView';

import CteDetails from './CteDetails';

const TripListCollapse = ({ entity }) => {
  const [openCollapseId, setOpenCollapseId] = useState(null);
  const [currentIdentifier, setCurrentIdentifier] = useState(null);
  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);
  const [currentCode, setCurrentCode] = useState(null);

  const isOpen = openCollapseId === entity.identifier;

  const toggleCollapse = () => {
    setOpenCollapseId(isOpen ? null : entity.identifier);
  };
  const calcCarbon = entity?.carbonTrack?.calculated + entity?.carbonTrack?.retired;

  const calcCarbonFormatted = calcCarbon.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  const handleOpen = (identifier, code) => {
    setCurrentIdentifier(identifier);
    setCurrentCode(code);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const percentage = (modality, status, typeExternalCode) => {
    let text;

    if (status === 'COMPLETED') {
      text = 'Viagem concluída';
    } else if (modality === 'FRETE_NAO_RASTREAVEL') {
      text = 'Porcentagem estimada de conclusão com base nos horários previstos';
    } else if (modality === 'APP_MOTORISTA' || typeExternalCode === 'OPENTECH') {
      text = 'Porcentagem estimada de conclusão com base na distância';
    } else {
      text = 'Carregando...';
    }

    return text;
  };

  const tooltipStart = (
    <>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
      {entity?.advanceTripStart == null && entity?.delayTripStart == null && <Text>No horário</Text>}
    </>
  );

  const tooltipEnd = (
    <>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
      {entity?.advanceTripEnd == null && entity?.delayTripEnd == null && <Text>No horário</Text>}
    </>
  );

  const renderDocsNumber = (entity) => {
    const { docsNumber } = entity ?? {};
    const docsLength = docsNumber?.length ?? 0;

    if (docsLength === 0) {
      return <Text>-</Text>;
    } else {
      const firstDoc = docsNumber[0] ?? '-';
      const remainingDocs = docsNumber.slice(1).join(', ');

      return (
        <>
          <Text>{firstDoc}</Text>
          {docsLength > 1 && renderTooltip(docsLength - 1, remainingDocs)}
        </>
      );
    }
  };

  const renderTooltip = (count, remainingDocs) => (
    <Tooltip label={remainingDocs}>
      <Box>{`+${count}`}</Box>
    </Tooltip>
  );

  return (
    <>
      <Tr h="80px" cursor="pointer" onClick={toggleCollapse}>
        <Td>
          <Box>{isOpen ? <MdKeyboardArrowUp color="#6C48C2" /> : <MdKeyboardArrowDown color="#6C48C2" />}</Box>
        </Td>

        <Td title={modalityTitle(entity.modality, entity.typeExternalCode, entity.externalCode)}>
          <Box>
            <Box h="1.1rem">{modalityIcon(entity?.modality, entity?.typeExternalCode, entity?.externalCode)}</Box>
          </Box>
        </Td>

        <Td>
          <Flex direction="column" align="flex-start !important" justify={entity?.externalCode ? 'space-between' : 'center'}>
            <Text fontSize="18px" fontWeight="bold" textColor="linkColor" onClick={(event) => event.stopPropagation()}>
              <Link to={`/logistic/trip/detail/${entity.identifier}`} target="_blank" cursor="pointer">
                #{entity?.code}
              </Link>
            </Text>

            {entity?.externalCode && (
              <Flex
                gap="5px"
                align="flex-start"
                width={
                  entity?.typeExternalCode == 'OPENTECH' || (entity.modality == 'APP_MOTORISTA' && entity?.externalCode?.length === 8)
                    ? '135px'
                    : 'auto'
                }>
                <Text fontSize="0.8rem">Cód.: {entity?.externalCode}</Text>

                <Tooltip label={generateLabelSelect(entity?.typeExternalCode)}>
                  <Box>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              </Flex>
            )}
          </Flex>
        </Td>

        <Td textColor="#0F0A1DB3" minW="190px">
          <Flex gap="5px">
            {entity?.clientsFormatted?.length === 0
              ? 'Nenhuma empresa vinculada'
              : entity?.clientsFormatted?.length + ` vinculada${entity?.clientsFormatted?.length > 1 ? 's' : ''}`}

            {entity?.clientsFormatted?.length > 0 && (
              <Tooltip label={entity?.clientsFormatted?.join(', ')}>
                <Box pl="2px">
                  <MdHelp color="#422C76" />
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Td>

        <Td textColor="#0F0A1DB3">
          <Box minW="200px">
            {entity?.userDriverName?.length > 25 ? (
              <Tooltip label={entity?.userDriverName}>{capitalize(entity?.userDriverName)}</Tooltip>
            ) : (
              capitalize(entity?.userDriverName)
            )}
          </Box>
        </Td>

        <Td textColor="#0F0A1DB3">
          <Flex direction="column" justify="space-between" align="flex-start !important" minW="200px">
            <Tooltip label="Data esperada">
              <Flex alignItems="center">
                <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />

                {entity?.startsAtFormatted ?? 'Data não disponível'}
              </Flex>
            </Tooltip>

            <Flex alignItems="center" gap="5px">
              <Tooltip label="Data efetiva">
                <Flex alignItems="center">
                  <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />

                  {entity?.startsEtdFormatted ?? 'Data não disponível'}
                </Flex>
              </Tooltip>

              {entity?.startsEtdFormatted != null && (
                <Tooltip label={tooltipStart}>
                  <Box>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Td>

        <Td textColor="#0F0A1DB3">
          <Flex direction="column" justify="space-between" align="flex-start !important" minW="200px">
            <Tooltip label={entity?.metrics?.endsMessage ?? 'Data esperada'}>
              <Flex align="flex-start !important" justify="center">
                <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />

                <Text>{entity?.metrics?.estimatedEndsAt ?? entity?.endsAtFormatted}</Text>
              </Flex>
            </Tooltip>

            <Flex alignItems="center" gap="5px">
              <Tooltip label="Data efetiva">
                <Flex alignItems="center">
                  <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />

                  {entity?.endsEtdFormatted ?? 'Data não disponível'}
                </Flex>
              </Tooltip>

              {entity?.endsEtdFormatted != null && (
                <Tooltip label={tooltipEnd}>
                  <Box>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Td>

        <Td textColor="#0F0A1DB3">
          <Flex align="center">
            <Tooltip label={percentage(entity.modality, entity.status, entity.typeExternalCode)}>
              <Text color="#8364CB">
                {entity?.percentageCompletion !== null ? `(${entity.percentageCompletion}%)` : 'Carregando...'}
              </Text>
            </Tooltip>
          </Flex>
        </Td>

        <Td>
          <Flex gap="5px" justify="space-between">
            <Flex direction="column" w="max-content">
              {renderDocsNumber(entity)}
            </Flex>

            {entity.qttFiles > 0 && (
              <Flex>
                <Tooltip label="Ver arquivos">
                  <Box>
                    <IconButton
                      bg="transparent"
                      icon={<MdOutlineListAlt size={20} color="#6C48C2" />}
                      onClick={(decision) => {
                        if (decision) {
                          decision.stopPropagation();
                          handleOpen(entity?.identifier, entity?.code);
                        }
                      }}
                    />
                  </Box>
                </Tooltip>
              </Flex>
            )}
          </Flex>
        </Td>

        <Td>
          <Flex align="center">
            <Tooltip label={descriptionStatusTrip(entity.status)}>
              <Flex
                w="full"
                maxW="150px"
                py="5px"
                px="10px"
                justify="center"
                align="center"
                borderRadius="15px"
                bgColor={convertColorTrip(entity.status)}
                color="white"
                maxH="30px">
                <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {descriptionStatusTrip(entity.status)}
                </Text>
              </Flex>
            </Tooltip>
          </Flex>
        </Td>

        <Td textColor="#0F0A1DB3">
          <Box minW="150px">{typeTransportTitle(entity?.typeTransport)}</Box>
        </Td>

        <Td>
          <Flex align="center" justify="flex-start" gap="5px">
            <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
              <Flex
                w="max-content"
                maxH="30px"
                p="7px 11px"
                border="1px"
                borderColor="#2ECC71"
                color="#2ECC71"
                direction="row"
                align="center"
                borderRadius="10px"
                gap="5px">
                Pegada CO₂: {calcCarbonFormatted}t
                {entity?.carbonTrack?.retired > 0 ? (
                  <Image src={processCheck} opacity={'1'} />
                ) : (
                  <Image src={processUncheck} opacity={'1'} />
                )}
              </Flex>
            </Tooltip>
          </Flex>
        </Td>

        <Td>
          <Flex align="center" justify="flex-end" gap="5px" w="auto" onClick={(event) => event.stopPropagation()}>
            <Tooltip label="Detalhe da viagem" placement="left">
              <Link to={`/logistic/trip/detail/${entity.identifier}`} target="_blank" cursor="pointer">
                <IconButton bg="transparent" icon={<MdOpenInNew size={20} color="#6C48C2" />} />
              </Link>
            </Tooltip>
          </Flex>
        </Td>
      </Tr>

      <Tr>
        <Td p="0" colSpan="13" border="none">
          <Box overflowX="auto">
            <CteDetails isOpenCollapse={isOpen} trip={entity} />
          </Box>
        </Td>
      </Tr>

      <DocumentModalView isOpen={isOpenModalViewFile} identifier={currentIdentifier} code={currentCode} onClose={handleOpen} />
    </>
  );
};

export default TripListCollapse;
