import React from 'react';

import { Flex } from '@chakra-ui/layout';

import DetailStep from './components/DetailStep';

const ProgressBar = ({ timeline }) => {
  return (
    <Flex py={{ base: '25px', md: '0' }} w="100%" px={{ base: '35px', md: '0' }}>
      {timeline?.elements?.map((step, key) => {
        {
          let isLast = timeline.elements.length !== key + 1;
          let type = key < timeline.now ? '' : key > timeline.now ? 'grey' : 'green';

          return <DetailStep key={key} title={step.title} desc={step.desc} hasProgress={isLast} type={type} delay={`0.${key}s`} />;
        }
      })}
    </Flex>
  );
};

export default ProgressBar;
