import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Flex, Image, Text, useDisclosure } from '@chakra-ui/react';

import add from '../../../assets/images/add.svg';
import Card from '../../../components/Card/Card';
import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicForm, DynamicFormButton, DynamicFormFooter } from '../../../components/CommonForm';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsDriver } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';
import DriversCard from '../components/Cards/DriversCard';
import DriversTable from '../components/Tables/DriversTable';

import DynamicFormDriverManagement from './components/DynamicFormDriverManagement';
// import ModalDriver from './components/ModalDriver';

const yup = require('yup');

function DriverManagementPage() {
  const hasPermission = permissions.logisticDriverManagement;

  const request = useRef(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isMobile, setIsMobile] = useState(false);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);

  const [initialValues, setInitialValues] = useState(null);
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    documentNumber: yup.string().required('Campo obrigatório'),
    email: yup.string().required('Campo obrigatório'),
    password: yup.string().required('Campo obrigatório'),
    cellphone: yup.string().required('Campo obrigatório'),
  });

  const load = (filters, key, page) => {
    setIsLoading(true);
    requests
      .listDrivers({
        filters: {
          ...filters.filters,
          search: filters?.search,
          role: ['ROLE_DRIVER'],
          page,
        },
      })
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        let options = [];
        data.data.map((driver) => {
          return options.push({
            identifier: driver.identifier,
            name: driver.name,
            email: driver.email,
            cellphone: driver.cellphone,
            status: driver.status,
            documentNumber: driver.documentNumber,
            type: driver.type,
            facial: driver?.enableFacialRecognition,
          });
        });

        setList(options);
        setMetadata(data?.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseRefresh = () => {
    onClose();
    triggerRefresh();
  };

  const exportListFABAction = (filters, key) => {
    //preset
    const endpoint = `/adm/user/export-csv`;
    const fileName = `drivers`;
    const extension = `csv`;
    const method = `GET`;
    const body = {};
    const params = {
      filters: {
        ...filters.filters,
        role: ['ROLE_DRIVER'],
      },
    };

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const getFilterOptions = async () => {
    setFilterOptions(filterOptionsDriver);
  };

  const handleOpenModalNew = () => {
    setInitialValues({
      type: 'INTERNAL',
      name: undefined,
      documentNumber: undefined,
      password: 'mudar123',
      email: undefined,
      phone: undefined,
      cellphone: undefined,
      additional: {
        RG: undefined,
        CNH: undefined,
        BIRTHDAY: undefined,
        ADDRESS_STREET: undefined,
        ADDRESS_NUMBER: undefined,
        ADDRESS_COMPLEMENT: undefined,
        ADDRESS_NEIGHBORHOOD: undefined,
        ADDRESS_CITY: undefined,
        ADDRESS_STATE: 'SP',
        ADDRESS_ZIPCODE: undefined,
      },
    });
    onOpen();
  };

  const generateData = (values) => {
    let additional = [];

    Object.entries(values?.additional)?.forEach(([key, value]) => {
      if (value != '' && value != undefined) {
        additional.push({ modality: key, description: value });
      }
    });

    return {
      ...values,
      additional: additional,
    };
  };

  const handleDriverNew = async (values) => {
    const valuesFormatted = generateData(values);
    await executeRequest({
      action: () => requests.addDriver(valuesFormatted),
      msgSuccess: 'Motorista criado com sucesso',
      msgError: 'Erro ao criar o motorista, tente novamente.',
      setIsLoadingNew,
      triggerRefresh,
      callback: () => onClose(),
    });
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="driver"
      title="Gestão de motoristas"
      breadcrumbs={[{ link: '#', title: 'Transporte' }]}
      textFilterPlaceholder="Nome, e-mail, telefone do motorista"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showFilters={true}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar Motorista',
          text: 'Cadastro de Motorista',
          action: (
            <Button variant="primary" pt="23px" pb="23px" borderRadius="50px" onClick={handleOpenModalNew}>
              <Image src={add} />
              <Text ml="10px">Novo motorista</Text>
            </Button>
          ),
          modality: 'custom',
        },
        {
          title: 'Baixar relatório',
          text: 'Baixar relatório de Motoristas (.csv)',
          action: exportListFABAction,
          modality: 'export-csv',
        },
      ]}>
      <CommonDrawer heading="Dados do motorista" placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <DynamicForm config={{ initialValues, validationSchema, callback: handleDriverNew }}>
          <DynamicFormDriverManagement />

          <DynamicFormFooter>
            <DynamicFormButton name={'Salvar'} type="button" />
          </DynamicFormFooter>
        </DynamicForm>
      </CommonDrawer>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Listagem de motoristas
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} motoristas
            </Text>
          </Flex>
        }>
        <Box p="15px">
          <ScreenLoader isLoading={isLoading}>
            {!isMobile ? (
              <DriversTable
                action={action}
                setAction={setAction}
                drivers={list}
                loadList={triggerRefresh}
                load={isLoading}
                setIsLoadingData={setIsLoading}
                metadata={metadata}
                setMetadata={setMetadata}
              />
            ) : (
              <DriversCard
                action={action}
                setAction={setAction}
                drivers={list}
                load={isLoading}
                metadata={metadata}
                setMetadata={setMetadata}
              />
            )}
          </ScreenLoader>
        </Box>
      </Card>
    </Page>
  );
}

export default DriverManagementPage;
