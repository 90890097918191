import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Image, Td, Text, Tooltip, Tr } from '@chakra-ui/react';

import cte from '../../../../../assets/svgs/cte.svg';
import ProgressBar from '../../../../../components/ProgressBar/ProgressBar';
import { modalityIcon, modalityTitle } from '../../../../../utils/translate';

const TableListCte = ({ tab, entity, setIsLoading }) => {
  let navigate = useNavigate();
  let hasChildren = false;

  const getColor = (modality) => {
    if (modality == 'FRETE_NAO_RASTREAVEL') {
      return '#E52359';
    }

    return '#6C48C2';
  };

  return (
    <Tr>
      <Td>
        <Box pl="20px" py="10px" bgColor="#F9F9F9">
          <Flex align="center" gap="10px">
            <Flex w="150px" align="center" gap="10px">
              <Image src={cte} />

              <Text textStyle="tableTitle" fontWeight="medium" color="#422C76" fontSize="1.3rem">
                {entity?.cteData && `CT-e: ${entity?.cteData?.number}`}
              </Text>
            </Flex>

            <Tooltip label="Detalhe da viagem">
              <Button
                h={'32px'}
                variant="secundary"
                fontSize="0.7rem"
                onClick={(decision) => {
                  if (decision) {
                    navigate(`/logistic/trip/detail/${entity?.driverFreight?.identifier}`, {
                      state: {
                        historyBack: '/logistic/trip#cte',
                      },
                    });
                  }
                }}>
                Viagem: #{entity?.driverFreight?.code}
              </Button>
            </Tooltip>

            {entity?.driverFreight?.externalCode && (
              <Tooltip label="Código externo (integração para rastreio)">
                <Button cursor="auto" h={'32px'} variant="secundary" fontSize="0.7rem">
                  Cód.: {entity?.driverFreight?.externalCode}
                </Button>
              </Tooltip>
            )}

            <Button
              cursor="auto"
              variant="secundary"
              fontSize="0.7rem"
              h={'32px'}
              color={getColor(entity?.driverFreight?.modality)}
              borderColor={getColor(entity?.driverFreight?.modality)}>
              <Flex alignItems="center" justifyItems="center">
                <Box h="1rem" mr="1">
                  {modalityIcon(
                    entity?.driverFreight?.modality,
                    entity?.driverFreight?.typeExternalCode,
                    entity?.driverFreight?.externalCode
                  )}
                </Box>
                {modalityTitle(
                  entity?.driverFreight?.modality,
                  entity.driverFreight?.typeExternalCode,
                  entity.driverFreight?.externalCode
                )}
              </Flex>
            </Button>
          </Flex>

          {entity?.timeline != null && entity?.timeline != undefined && (
            <Box pt="10px" w="99%">
              <ProgressBar timeline={entity?.timeline} />
            </Box>
          )}
        </Box>
      </Td>
    </Tr>
  );
};

export default TableListCte;
