import { seaRoute } from 'searoute-ts';

import { reduceCheckpointListByDistance } from '../../../utils/geolocation';

const convertCoordinates = (coordinates, extraProps = []) => {
  return coordinates.map((coord, index) => {
    const point = { lat: coord[1], lng: coord[0] };
    if (extraProps[index]) {
      Object.assign(point, extraProps[index]);
    }
    return point;
  });
};

export const generateRoute = async (path) => {
  const points = [];

  if (!Array.isArray(path) || path.length < 2) {
    console.error('Invalid path:', path);
    return points;
  }

  const origin = path[0];
  const destination = path[path.length - 1];

  console.timeLog('debug', origin);

  const originFeature = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Point',
      coordinates: [origin?.lng, origin?.lat],
    },
  };
  const destinationFeature = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Point',
      coordinates: [destination?.lng, destination?.lat],
    },
  };

  const originProps = { ...origin };
  const destinationProps = { ...destination };

  // Remove properties
  delete originProps.lat;
  delete originProps.lng;
  delete destinationProps.lat;
  delete destinationProps.lng;

  try {
    const route = seaRoute(originFeature, destinationFeature);

    const extraProps = route.geometry.coordinates.map((_, index) => {
      if (index === 0) return originProps;
      if (index === route.geometry.coordinates.length - 1) return destinationProps;
      return {};
    });

    const convertedPoints = convertCoordinates(route.geometry.coordinates, extraProps);
    points.push(...convertedPoints);
  } catch (error) {
    console.error('Error generating route:', error);
  }

  let aux = [];
  points?.length > 0 &&
    points.map((point) => {
      aux.push({
        latitude: point.lat,
        longitude: point.lng,
      });
    });

  const pointsReduced = reduceCheckpointListByDistance(aux, 10);

  const result = [];
  pointsReduced &&
    pointsReduced.map((current) => {
      result.push({
        lat: current.latitude,
        lng: current.longitude,
      });
    });

  return result;
};
