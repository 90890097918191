import { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Image, Text } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { importFileAction } from '../../../utils/actions/importFileAction';
import { add } from '../../../utils/exports';
import { filterOptionsNcm } from '../../../utils/filters/filterPresets';

import { commonListConfig } from './components/commonListConfig';

const NcmManagementPage = () => {
  const request = useRef(0);
  const hasPermission = permissions.vendemmiaNcmManagement;
  const [filterOptions, setFilterOptions] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [list, setList] = useState([]);

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const load = (filters, key) => {
    fetchNCMListData(filters, metadata.current_page, 25);
  };

  // Utilizção do hook personalizado para o refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const loadMore = (filters, key) => {
    setIsLoading(true);
    setIsLoadingMore(true);
    requests.listProcess(filters, metadata.current_page + 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }

      request.current = key;

      setList((prevList) => [...prevList, ...data.data]);
      setMetadata(data.meta);
      setIsLoading(false);
      setIsLoadingMore(false);
      triggerRefresh();
    });
  };

  const fetchNCMListData = async (filters, page, pageSize) => {
    setIsLoading(true);

    try {
      const res = await requests.fetchNCMList(filters, page, pageSize);
      setList(res.data);
      setMetadata(res.meta);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
    setIsLoading(false);
  };

  const subtitle = `Existe um total de ${metadata.total_count} ncm's`;

  const exportListFABActionCSV = () => {
    //preset
    const extension = `csv`;
    const endpoint = `/ncm/export/${extension}`;
    const fileName = ``;
    const body = {};
    const filters = {};

    //generate file
    return generateExportFile(endpoint, fileName, extension, body, filters);
  };

  const exportListFABActionExcel = () => {
    //preset
    const extension = `xlsx`;
    const endpoint = `/ncm/export/${extension}`;
    const fileName = ``;
    const body = {};
    const filters = {};

    //generate file
    return generateExportFile(endpoint, fileName, extension, body, filters);
  };

  const uploadFABActionDocuments = (docs, config) => {
    return importFileAction({
      endpoint: `/ncm/upload`,
      docs,
      config,
      setIsLoading,
      triggerRefresh,
    });
  };

  /*   const updatedData = list.map((item, index) => ({
    ...item,
    role: mockRoles[index],
  })); */

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    load();
  }, [action]);

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsNcm());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="ncm-management"
      title={`Gestão de NCMs`}
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      textFilterPlaceholder="Buscar por código ou descrição"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      loadMore={loadMore}
      isContentLoadingMore={isLoadingMore}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingMore}
      showRefreshData={false}
      allowFiltersOverride={false}
      disableAdditionalFields={true}
      disableMultiUpload={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar NCM',
          text: 'Cadastro de NCM',
          action: (
            <Link to="/vendemmia/ncm-management/new" key={2}>
              <Button variant={'floatingGreenButton'}>
                <Text mr="10px" color="white">
                  Cadastrar
                </Text>
                <Image src={add} />
              </Button>
            </Link>
          ),
          modality: 'custom',
        },
        {
          title: 'Baixar relatório em (.csv)',
          text: 'Baixar relatório de NCMs (.csv)',
          action: exportListFABActionCSV,
          modality: 'export-csv',
        },
        {
          title: 'Baixar relatório em (.xlsx)',
          text: 'Baixar relatório de NCMs (.xlsx)',
          action: exportListFABActionExcel,
          modality: 'export-csv',
        },
        {
          title: 'Importar',
          text: 'Importar novo arquivo',
          action: uploadFABActionDocuments,
          modality: 'upload-files',
        },
      ]}>
      <Box m={'0 10px'}>
        <CommonList
          //lists
          list={list}
          rawData={list}
          //actions
          action={action}
          sorting={sorting}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          handleSort={handleSort}
          //loading
          isLoading={isLoading}
          //props
          {...commonListConfig}
        />
      </Box>
    </Page>
  );
};

export default NcmManagementPage;
