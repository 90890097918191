import React, { useEffect, useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { Button, Flex, FormLabel, Input, Text, useDisclosure } from '@chakra-ui/react';

import { CommonModal } from '../../../components/CommonModal/CommonModal';
import BackgroundLogin from '../../../components/Login/BackgroundLogin/BackgroundLogin';

import SignatureResult from './components/SignatureResult';

// Default initial values
const DEFAULT_VALUES = {
  name: '',
  role: '',
  email: '',
  phone: '',
  address: '',
};

// Address options for the Select component
const ADDRESS_OPTIONS = [
  { value: 'Rua Funchal, 129 – 13° Andar São Paulo, SP, Brasil, CEP: 04551-060', label: 'São Paulo (Vila Olímpia)' },
  { value: 'Av Marcos Conder, 805 – 8° Andar -  Centro – Itajaí, SC, Brasil, CEP: 88301-123', label: 'Santa Catarina (Itajaí)' },
  { value: 'Rodovia BR 470 – KM 7, 6800 - Volta Grande, Navegantes/SC CEP: 88371-890', label: 'Armazém SC CD1 (BR 470 – KM 7, 6800)' },
  { value: 'Rodovia BR 470 – 4669 - Volta Grande, Navegantes/SC CEP: 88371-890', label: 'Armazém SC CD2 (BR 470 – 4669)' },
  { value: 'Avenida Portugal, 325 - Bairro Itaqui, Itapevi', label: 'Armazém SP (Itapevi)' },
];

// Function to combine form values
const combineValues = (initialValues, dataState) => {
  const mergedValues = { ...initialValues };

  if (dataState) {
    for (const key in dataState) {
      if (dataState[key]) {
        mergedValues[key] = dataState[key];
      }
    }
  }

  return mergedValues;
};

// Form component
const FormFields = ({ values, handleChange, setFieldValue }) => (
  <>
    {['name', 'role', 'email', 'phone'].map((field) => (
      <Flex key={field} direction="column">
        <FormLabel color="white" m="0">
          {field === 'name' ? 'Nome' : field === 'role' ? 'Cargo' : field === 'email' ? 'E-mail' : 'Telefone'}
        </FormLabel>
        <Input
          variant="flushed"
          h="40px"
          color="#FFFFFF"
          name={field}
          _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
          _placeholder={{ color: '#FFFFFF' }}
          value={values[field] || ''}
          onChange={handleChange}
        />
        <ErrorMessage component="p" className="error-message-error" name={field} />
      </Flex>
    ))}
    <Flex direction="column">
      <Select
        placeholder="Selecione um endereço"
        name="address"
        options={ADDRESS_OPTIONS}
        value={ADDRESS_OPTIONS.find((e) => e.value === values.address) || null}
        onChange={(option) => setFieldValue('address', option?.value || '')}
      />
      <ErrorMessage component="p" className="error-message-error" name="address" />
    </Flex>
  </>
);

const SignaturePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataState = location.state;

  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES);
  const [userData, setUserData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isOpen: modalViewIsOpen, onOpen: modalViewOnOpen, onClose: modalViewOnClose } = useDisclosure();

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('userData'));
    if (savedData) {
      setInitialValues(savedData);
    }
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    if (!values.address) {
      alert('Por favor, preencha o endereço.');
      setSubmitting(false);
      return;
    }

    localStorage.setItem('userData', JSON.stringify(values));
    setUserData(values);
    setIsSubmitting(true);
    modalViewOnOpen();

    setTimeout(() => {
      setIsSubmitting(false);
    }, 300);
  };

  return (
    <BackgroundLogin showLogo={false}>
      <Formik initialValues={combineValues(initialValues, dataState)} onSubmit={handleSubmit} enableReinitialize>
        {({ handleSubmit, handleChange, setFieldValue, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Flex direction="column" className="down-anim">
              <Text textStyle="subtitle" textColor="linkColor" mb="20px">
                Assinatura
              </Text>
              <Flex direction="column" gap="20px">
                <FormFields values={values} handleChange={handleChange} setFieldValue={setFieldValue} />
              </Flex>
              <Flex
                w="full"
                mt={{ sm: '20px', lg: '30px' }}
                gap={{ sm: '0px', lg: '15px' }}
                justify={{ sm: 'space-between', lg: 'end' }}
                className="down-anim">
                <Button
                  variant="outline"
                  height="40px"
                  borderRadius="5px"
                  borderColor="#FF2F69"
                  textColor="#FF2F69"
                  transition=".3s"
                  _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
                  isDisabled={isSubmitting}
                  onClick={() => navigate('/')}>
                  <Text>Voltar</Text>
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  height="40px"
                  borderRadius="5px"
                  bgColor="#FF2F69"
                  textColor="#FFFFFF"
                  transition=".3s"
                  _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
                  isLoading={isSubmitting}>
                  <Text>Gerar assinatura</Text>
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>

      <CommonModal
        heading="Assinatura de email"
        subtitle="A assinatura foi criada, agora basta copiar e adicionar ao seu email!"
        isOpen={modalViewIsOpen}
        onOpen={modalViewOnOpen}
        onClose={() => {
          modalViewOnClose();
          setIsSubmitting(false);
        }}
        size="3xl"
        removeRadius>
        <SignatureResult userData={userData || {}} />
      </CommonModal>
    </BackgroundLogin>
  );
};

export default SignaturePage;
